<template v-if="show">
  <div class="grid">
    <div class="col-12">
      <div v-if="loading" class="spinner-border text-primary" role="status">
        <i class="p-datatable-loading-icon pi-spin pi pi-spinner load_chart"></i>

      </div>
      <div v-else class="col-12">
        <div class="card widget-chat">
          <div class="flex">
            <button class="p-link" id="btn_back_message" @click="backToMessage">
              <i class="pi pi-angle-left"></i>
            </button>
            <span><small>{{ getCreatedAtDiscussion }}</small></span>
            <span v-if="typeof siteFullName === 'string' && siteFullName" style="margin-left: auto;"><small>{{ siteFullName }}</small></span>
          </div>
          <h5>{{ getChatTitle }}</h5>
          <ul>
            <li v-for="(m, i) of messages" :key="i"
                :class="m.sender.is_hotline === false ?  'clearfix message-own' : 'clearfix message-from'" class="list-message">
              <span class="text-message-disc" style="background-color: white; border-color: white; margin: 0 !important; padding: 0 !important;">
              <span style="background-color: white; border-color: white"><small style=" font-size: x-small; ">{{
                  getFormattedDate(m.created_at)
                }}</small></span><br>
              <span v-html="m.message_text" style="width: fit-content;"></span>
            </span>
              <div class="title-file-message">
                <span v-for="(f,i) of m.message_files" :key="i"><i
                    @click="attachFile('/api/message_files/' + f.id, f.name)" class="pi pi-file" style="cursor: pointer">
                {{ f.name }}
              </i></span>
              </div>

            </li>
          </ul>
          <div class="new-message">
            <div class="message-attachment" @click="isHaveFile = !isHaveFile" style="cursor: pointer">
              <i class="pi pi-paperclip"></i>
            </div>
            <div class="message-input">
              <!--              <input v-model="textValue" type="text" placeholder="Votre message" class="p-inputtext" />-->
              <Editor v-model="textValue" :placeholder="t('yourMessage')">
                <template #toolbar>
              <span class="ql-formats">
                        <select class="ql-header"></select>
                        <select class="ql-font"></select>
                        <button class="ql-bold"></button>
                        <button class="ql-italic"></button>
                        <button class="ql-underline"></button>
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
              </span>
                  <span class="ql-formats">
                 <button class="ql-list" value="ordered"></button>
                 <button class="ql-list" value="bullet"></button>
                 <select class="ql-align"></select>
              </span>
                  <span class="ql-formats">
                 <button class="ql-link"></button>
                 <button class="ql-image"></button>
                 <button class="ql-code-block"></button>
              </span>
                  <span class="ql-formats">
                  <button class="ql-clean"></button>
              </span>
                </template>
              </Editor>
            </div>
            <Button label="Envoyer" icon="pi pi-send" @click="sendMessage" :disabled="sendMessageDisabled"/>
          </div>
          <FileUpload v-if="isHaveFile" ref="fileUploadDiscussionChat" name="fileArray[]" :multiple="true"
                      :chooseLabel="'Rejoindre fichiers'" :cancelLabel="' '" :showUploadButton="false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HotlineService from "@/service/HotlineService";
import {usePrimeVue} from "primevue/config";
import {computed} from "vue";
import moment from "moment-timezone";
import {useRoute} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import {saveAs} from 'file-saver';
import {useStore} from "vuex";
import Editor from "primevue/editor";

export default {
  name: "Chat",
  components: {
    Editor
  },
  setup() {
    const site = computed(() => {
      return store.getters.currentSite
    });
    const primevue = usePrimeVue();
    const t = primevue.config.StoreTranslation.t
    const user = computed(() => {
      return store.getters.currentUser
    });
    const route = useRoute()
    const store = useStore();
    const discussionId = computed(() => {
      return route.params.id
    });
    const user_hotline = user.value.is_hotline
    return {primevue, t, store, user, discussionId, site, user_hotline};
  },
  data() {
    return {
      messages: [],
      siteFullName: [],
      textValue: '',
      chatInterval: null,
      isHaveFile: false,
      loading: false,
      show: true,
      sendMessageDisabled: false,
    }
  },
  watch: {
    discussionId() {
      this.loading = true
      this.getMessages(this.$route.params.id)
    },
  },
  computed: {
    getChatTitle() {
      if (this.messages?.length === 0) return 'Discussion générale'
      return this.messages[0].discussion.topic
    },
    getCreatedAtDiscussion() {
      if (this.messages?.length === 0) return ''
      return `Discussion lancée à ${moment(this.messages[0].discussion?.created_at).format('DD/MM/YYYY HH:mm:ss')}`
    },
  },
  hotlineService: null,
  mounted() {
    if (this.$route.params?.id) {
      this.loading = true
      this.setIntervalFn()
      this.interval = setInterval(() => {
        this.getMessages(this.$route.params.id)
      }, 60000);
    }
  },
  created() {
    this.hotlineService = new HotlineService();

  },
  beforeUnmount() {
    clearInterval(this.chatInterval)
  },
  methods: {
    backToMessage() {
      this.$router.push('/ui-ax/discussions');
    },
    attachFile(idMedia, name) {
      this.store.dispatch(Actions.ATTACH_FILES, {idMedia: idMedia, 'customHeader': true}).then((data, filename) => {
        const url = window.URL.createObjectURL(data, filename);
        saveAs(url, name)
      })
    },
    setIntervalFn() {
      this.getMessages(this.$route.params.id)
      this.interval = setInterval(() => {
        this.getMessages(this.$route.params.id)
      }, 60000);
    },
    sendMessage() {
      this.sendMessageDisabled = true
      if (!this.discussionId) {
        this.sendDiscussion()
        return false;
      }
      let files = this.$refs.fileUploadDiscussionChat?.files ?? []
      let filedId = [];
      files.forEach((f, i) => {
        let json = {
          type: f.type,
          name: f.name,
          size: f.size,
          file: f,
        }
        this.hotlineService.uploadFile(json).then((data) => {
          if (data.status === 201) {
            filedId.push(data.data.id)
            if (i === files.length - 1) {
              this.postSendMessage(filedId)
            }
          }
        })
      })
      if (files.length === 0) {
        this.postSendMessage(filedId)
      }
    },
    postSendMessage(filedId) {
      let payload = {
        discussionId: this.$route.params.id,
        message: this.textValue,
        filesMessage: filedId,
        currentUserId: this.user.id,
      }
      this.hotlineService.sendMessage(payload).then((data) => {
        if (data.status === 201) {
          this.sendMessageDisabled = false
          this.textValue = '';
          if (this.$refs.fileUploadDiscussionChat?.files)
            this.$refs.fileUploadDiscussionChat.files = []
          this.isHaveFile = false
          this.getMessages(this.$route.params.id)
        }
      })
    },
    getMessages(id) {
      if (!id) return false
      this.hotlineService.getMessageByDiscussionId({discussion: id,  customHeader: false, application_master: this.user.application_master}).then((data) => {
        if (data.status === 200) {
          this.loading = false
          this.messages = data.data
          this.siteFullName = this.messages && this.messages.length > 0 ? this.messages[0].discussion.site?.path?.full_path: ''
        }
      })
    },
    getFormattedDate(x) {
      return moment(x).format('DD/MM/YYYY HH:mm:ss')
    },
    sendDiscussion() {
      let files = this.$refs.fileUploadDiscussionChat?.files ?? []
      let filedId = [];
      files.forEach((f, i) => {
        let json = {
          type: f.type,
          name: f.name,
          size: f.size,
          file: f,
        }
        this.hotlineService.uploadFile(json).then((data) => {
          if (data.status === 201) {
            filedId.push(data.data.id)
            if (i === files.length - 1) {
              this.postSendDiscussion(filedId)
            }
          }
        })
      })
      if (files.length === 0) {
        this.postSendDiscussion(filedId)
      }
    },
    postSendDiscussion(filedId) {
      let payload = {
        documentId: this.dataCurrentDiscussion?.id,
        topic: this.dataCurrentDiscussion?.title,
        message: this.textValue,
        files: filedId,
        currentUserId: this.user.id,
        currentSiteId: this.site.id
      }
      this.hotlineService.sendDiscussion(payload).then((data) => {
        if (data.status === 201) {
          this.isHotline = false;
          this.textValue = '';
          this.show = false
          this.$router.push({path: '/ui-ax/chat/' + data.data.id, params: {id: data.data.id, topic: data.data.data}});
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/demo/styles/badges.scss';

.widget-chat {
  button#btn_back_message {
    padding-right: 11px;

    i {
      font-size: 20px;
    }
  }

  ul {

    .message-from {
      padding: 0;

      span {
        font-size: 12px;
        background-color: #eb9d28a3;
        border: solid 1px #eb9d28a3;
      }
    }

    .message-own {
      span {
        font-size: 12px;
      }
    }
  }

  .new-message {
    display: flex;
    align-items: center;
    height: auto;
    border: 1px solid var(--surface-border);

    .message-attachment {
      border-right: 1px solid var(--surface-border);
      width: 40px;
      line-height: 1;
      height: 33px;
      padding: 6px;

      i {
        font-size: 20px;
      }
    }

    .message-input {
      top: 0;

      input {
        background-color: #efefef;
        padding-left: 8px;
        border: 1px solid #efefef;
        font-size: 12px;
      }
    }

  }
}

</style>
